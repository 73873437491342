const filemanRootUrl = window.location.origin + process.env.BASE_URL + "lib/fileman/index.html";
console.log("Public Path", process.env)
const CKEditorConfig = {
    filebrowserBrowseUrl: filemanRootUrl,
    filebrowserImageBrowseUrl: filemanRootUrl + '?type=image',
    removeDialogTabs: 'link:upload;image:upload'
}  

export const globalConfigs = {
    CKEditorConfig
};
