<template>
	<AddEditForm :headerTitle="$t('pages.emails.editTemplate')" :onGoBack="onGoBack" :showDelete="false">
		<template v-slot:headerAddEdit>
			<button type="button" name="edit-emailtemplate" class="btn btn-primary" @click="onSave">
				{{ $t('common.save') }}
			</button>			
		</template>
		<template #content>
			<Accordion :headerTitle="$t('common.generalInfo')" v-if="emailTemplateDetail != null">
				<template v-slot:body>
					<CForm>
						<CInput :placeholder="$t('pages.emails.enterName')" v-model="emailTemplateDetail.name" :is-valid="validator" required horizontal>
							<template #label>
								<div class="col-sm-3 col-form-label">
									{{ $t('pages.emails.templateName') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>

						<CInput :placeholder="$t('pages.emails.entersubject')" v-model="emailTemplateDetail.subject" :is-valid="validator" required horizontal>
							<template #label>
								<div class="col-sm-3 col-form-label">
									{{ $t('pages.emails.subject') }}
									<small class="requiredInput">(*)</small>
								</div>
							</template>
						</CInput>

						<CInput :label="$t('pages.emails.bccEmailAddresses')" :placeholder="$t('pages.emails.enterbccEmailAddresses')" v-model="emailTemplateDetail.bccEmailAddresses" horizontal></CInput>
						<CInput :label="$t('pages.emails.delayBeforeSend')" :placeholder="$t('pages.emails.enterdelayBeforeSend')" v-model="emailTemplateDetail.delayBeforeSend" horizontal @keypress="$func.onlyNumbers" maxlength="10"></CInput>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">
								{{ $t('pages.emails.messagetemplate') }} <small class="requiredInput">(*)</small>
							</label>
							<div class="col-sm-9">
								<ckeditor v-model="emailTemplateDetail.body" :config="editorConfig"></ckeditor>
							</div>
						</div>

						<div role="group" class="form-group form-row">
							<label class="col-sm-3 col-form-label">{{ $t('common.active') }}</label>
							<div class="col-sm-9 input-group">
								<CSwitch :key="emailTemplateDetail.id"
										 color="info"
										 :checked.sync="emailTemplateDetail.isActive"
										 size="sm" />
							</div>
						</div>
					</CForm>
				</template>
			</Accordion>
		</template>
	</AddEditForm>
</template>

<script>	
    import i18n from '@/plugins/i18n'
	import { mapGetters } from 'vuex'
    import { globalConfigs } from '@/global/index'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'

	export default {
        name: 'EditEmailTemplate',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
                editorConfig: globalConfigs.CKEditorConfig,
			}
		},
		computed: {
            ...mapGetters('emailTemplate', ['emailTemplateDetail']),
		},
		components: {
            Accordion, AddEditForm
		},
		methods: {
            onSave() {
                this.onSaveNewBank();
			},			
            onGoBack() {
                this.$router.push('/emailTemplate/list');
            },
			validator(val) {				
				return val ? val.length > 0 : false
            },						
			async onSaveNewBank() {
				
                if (!this.validator(this.emailTemplateDetail.name))
				{					
                    this.$store.dispatch('alert/error', this.$t('pages.emails.enterName'));
					return;
				}

                if (!this.validator(this.emailTemplateDetail.subject)) {
                    this.$store.dispatch('alert/error', this.$t('pages.emails.entersubject'));
                    return;
				}

                if (!this.validator(this.emailTemplateDetail.body)) {
                    this.$store.dispatch('alert/error', this.$t('pages.emails.entermessagetemplate'));
                    return;
                }
				
                var response = await this.$store.dispatch("emailTemplate/saveEmailTemplateInfo");

                if (response.success == true) {
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.emails.noticeEditEmailTemplateSuccess') }, { root: true });
                    this.onGoBack();
                }
                else {
                    let message = i18n.t('pages.emails.noticeAddEmailTemplateFailed');
                    if (response.errorMessage === 'Existed') 
                        message = i18n.t('pages.emails.noticeEditEmailTemplateFailed');
                    else if (response.errorMessage === 'Invalid data input')
                        message = i18n.t('pages.emails.checkDataInput');

                    this.$store.dispatch('alert/error', message, { root: true });
				}
                              
			},			
		},
		mounted() {			
            this.$store.dispatch("emailTemplate/getEmailTemplateDetail", this.$route.params.id);
		}
	}
</script>