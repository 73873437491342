<template>
    <CRow>
        <CCol sm="12">
            <TopHeadline :headerTitle="headerTitle" :backTitle="backTitle" :onGoBack="onGoBack">
                <template v-slot:headerRight>
                    <slot name="headerLeft"></slot>
                    <slot name="headerAddEdit">
                        <button type="button" name="save" class="btn btn-primary" @click="handleSave">
                            {{ $t('common.save') }}
                        </button>
                        <button type="button" name="save-continue" class="btn btn-primary" @click="handleSaveContinue">
                            {{ $t('common.saveAndContinue') }}
                        </button>
                    </slot>
                    <slot name="headerRight"></slot>
                    <slot name="delete" v-if="showDelete==true">
                        <button type="button" name="delete" class="btn btn-danger" @click="handleDelete">
                            {{ $t('common.delete') }}
                        </button>
                    </slot>
                </template>
            </TopHeadline>

            <slot name="content">
                Default Content
            </slot>
        </CCol>
    </CRow>
</template>

<script>
    import TopHeadline from './TopHeadline.vue'
    import i18n from '@/plugins/i18n'

    export default {
        name: 'AddEditForm',
        props: {
            headerTitle: {
                default: "Default Header",
                type: String
            },
            backTitle: {
                default: i18n.t('common.back'),
                type: String
            },
            showGoBack: {
                default: false,
                type: Boolean
            },
            showDelete: {
                default: true,
                type: Boolean
            },
            searchBoxTitle: {
                default: i18n.t('common.search'),
                type: String
            },
            onGoBack: { type: Function },
            onDelete: { type: Function },
            onSave: { type: Function },
            onSaveContinue: { type: Function }
        },
        data() {
            return {
                
            };
        },
        components: { TopHeadline },
        methods: {
            handleDelete() {
                if (this.onDelete instanceof Function)
                    this.onDelete();
            },
            handleSave() {
                console.log("HANDLE SAVE");
                if (this.onSave instanceof Function)
                    this.onSave();
            },
            handleSaveContinue() {
                console.log("HANDLE SAVE AND CONTINUE");
                if (this.onSaveContinue instanceof Function)
                    this.onSaveContinue();
            },
        }
    }
</script>

<style scoped>
    .card {
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border-radius: .25rem;
    }

        .card .card-header {
            background-color: #fff;
        }

    h5 {
        font-size: 1.09375rem;
    }

        h5 a {
            /* color: #fff;*/
            color: #444;
        }

    .mb-0 {
        margin-bottom: 0 !important;
    }
</style>
